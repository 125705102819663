.popularPost .box {
  margin: 15px 15px 15px 0;
}
.popularPost img {
  width: 100%; /* Establece un ancho fijo para todas las imágenes */
  height: 300px; /* Establece una altura fija para todas las imágenes */
  object-fit: cover; /* Escala la imagen para cubrir el contenedor, recortando si es necesario */
}

.popularPost .text {
    padding: 20px;
    background-color: #F5F5F5;
    border-bottom-left-radius: 15px; /* Redondea la esquina inferior izquierda */
    border-bottom-right-radius: 15px; /* Redondea la esquina inferior derecha */
}


.corrientes-img img {
  width: 100%; /* Establecer el ancho deseado */
  height: 200px; /* Establecer la altura deseada */
  object-fit: cover; /* Escalar y recortar la imagen según el tamaño especificado */
}

