.tpost .box {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;}
  
.tpost .img {
  width: 100px;
  height: 80px;
}
.tpost img {
  width: 100px;
  height: 80px;
  object-fit: cover;
}
.tpost .text {
  padding: 0 15px;
}
.tpost h1 {
  font-size: 15px;
}
.tpost span {
  font-size: 12px;
  color: grey;
}
@media screen and (max-width: 768px) {
  .tpost .img,
  .tpost img {
    width: 200px;
    height: 150px;
  }
  .tpost .text {
    width: 100%;
  }
}
