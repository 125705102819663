footer {
  background-color: #000;
  background-image: url('/public/images/contact_bg.png');
  color: #fff;
  padding: 50px 0;
  margin-top: 50px;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Estilos del logo y la información de contacto */
.logo {
  flex: 1;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: center;
}

.logo-footer {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  margin-left: 80px;
}

.parrafo-footer {
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
  
}

/* Estilos del formulario de contacto */
.contact-form {
  flex: 1;
  text-align: left;
  padding: 0 20px;
  box-sizing: border-box;
}

.contact-form h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

.form-group.full-width textarea {
  height: 150px;
}

button[type="submit"] {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.titulo-formulario-footer {
  text-align: center;
}

/* Estilos de la sección legal */
.legal {
  padding: 15px;
  background-color: #000;
  color: grey;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  text-align: center;
}

.legal i {
  color: red;
}

/* Estilos responsivos */
@media screen and (max-width: 768px) {
  .logo,
  .contact-form {
    flex: none;
    width: 100%;
    padding: 0;
  }

  .form-group.full-width textarea {
    height: 100px;
  }
}

.mapa-footer{
  width: 100%;
  height: 300px;
  margin-top: 30px;
  margin-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.div-boton-form{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ok {
  margin-right: 80px;
  background: url('https://sadopentrerios.org/wp-content/uploads/2016/06/bandera-cua.jpg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: 900;
  background-position: center;
  text-align: center; 
  line-height: 1.5;
}

/* Estilo personalizado para el modal de SweetAlert2 */
.swal2-popup {
  background-color: #000; /* Fondo negro */
  color: #fff; /* Texto blanco */
  border: 2px solid #fff; /* Borde blanco con 2 píxeles de ancho */
  border-radius: 8px; /* Ajusta según sea necesario */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Sombra blanca alrededor */
}

/* Estilo para la imagen dentro del modal */
.swal2-image {
  max-width: 100%;
  max-height: 200px; /* Ajusta según sea necesario */
}