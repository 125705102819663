.singlePopular {
  overflow: hidden;
}
.singlePopular .box {
  width: 100%;
  height: 150px;
  position: relative;
  cursor: pointer;
}
.singlePopular img {
  width: 90%;
  height: 100px;
  border-radius: 5px;
}
.singlePopular .text {
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 10px;
}
.singlePopular .text h1 {
  font-size: 13px;
  color: white;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.5);
}
