/* Estilos generales */
.head {
  cursor: pointer;
}

.head .logo img {
  width: 700px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .head .logo img {
    width: 340px;
    height: auto;
  }
}

header {
  background-color: #000;
  color: #fff;
}

header li {
  margin-right: 40px;
  transition: 0.5s;
  margin-top: 13px;
}

header li:hover {
  color: crimson;
  cursor: pointer;
}

header a {
  color: #fff;
  padding: 7px;
}

header a:hover {
  color: crimson;
}

.barIcon {
  font-size: 25px;
  background: none;
  color: #fff;
  display: none;
  border: none;
}

/* Estilos para pantallas pequeñas (hasta 768px) */
@media screen and (max-width: 768px) {

  .head .ad,
  header .icon,
  header .flex {
    display: none;
  }

  .barIcon {
    display: block;
  }

  header .navbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    left: 0;
    top: 21%;
    transition: 0.5s;
    width: 100%;
    height: 50vh;
    z-index: 5;
    padding: 50px;
  }

  header .navbar li {
    margin-top: 10px;
  }

  .titulo-navbar {
    margin-right: 0;
    font-size: 1rem;
    line-height: 1.2;

  }
}

/* Estilos para pantallas medianas (769px - 1200px) */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  header .navbar li {
    margin-top: 33px;
  }

  .titulo-navbar {
    margin-right: 0;
    font-size: 1rem;
    line-height: 1.2;
  }
}

/* Estilos para pantallas grandes (más de 1200px) */
@media screen and (min-width: 1201px) {
  header .navbar li {
    margin-top: 10px;
  }
}

/* Estilos comunes a todas las pantallas */
.titulo-navbar {
  margin-right: 80px;
  background: url('https://sadopentrerios.org/wp-content/uploads/2016/06/bandera-cua.jpg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: 900;
  background-position: center;
  text-align: center;
  line-height: 1.5;
}


.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
}

