.weather-container {
  padding: 20px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  border: 1px solid black;
  margin-bottom: 1rem;
  background: 
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Capa de color semitransparente */
    url('/public/images/clima/corrientes.jpg') no-repeat center center/cover; /* Ruta relativa de la imagen */
  color: white; /* Para que el texto sea legible sobre la imagen */
}
  
  .weather-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .weather-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .temp {
    color: rgb(20, 87, 222);
  }

  .sol {
    color: rgb(194, 194, 10);
  }
  
  
  .weather-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .weather-text {
    font-size: 1rem;
  }
  