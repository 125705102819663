.popular .box {
  background-color: #fff;
  display: flex;
  /* #18  copy*/
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;*/
  position: relative;
  margin: 15px 15px 15px 0;
  /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;*/
}
.popular .images {
  position: relative;
  height: 150px;
}
.popular img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.popular .text h1 {
  padding: 20px 0 0 20px;
  margin: 0;
  color: #000;
}
.popular .date {
  margin-top: 15px;
  padding: 0 0 0 20px;
}

.popular .comment {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0 0 20px;
  margin-top: 5px;
}
.publicidad-seccion-noticias{
  height: 200px !important;
  width: 100%;

  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.Publicidad-Section-Noticias{
  height: 220px !important;
}